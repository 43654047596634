<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title mt-2 mb-0">
        {{ header }}
        <div class="mb-0 float-right">
          <span class="label" v-if="this.name != ''">Name:</span>
          {{ this.name }}
        </div>
      </h4>
    </div>
    <div class="card-body" v-if="this.contact">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="d-flex flex-column">
            <div class="mb-2">
              <span class="label">Username:</span>
              {{ this.contact.username }}
            </div>
            <div class="d-flex">
              <div><span class="label">Address:</span></div>
              <div class="d-flex flex-column pl-2">
                <div>{{ this.contact.street1 }}</div>
                <div v-if="this.contact.street2 != ''">
                  {{ this.contact.street2 }}
                </div>
                <div>
                  {{ this.contact.city }},
                  {{ this.contact.state }}
                  {{ this.contact.zipcode | zipCode }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="d-flex flex-column">
            <div class="mb-2">
              <span class="label">Phone:</span>
              {{
                this.contact.mobilePhone
                  ? this.contact.mobilePhone
                  : this.contact.homePhone | formatPhoneNumber
              }}
            </div>
            <div class="mb-2">
              <span class="label">Email:</span>
              {{ this.contact.email }}
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <span class="label" v-if="!!this.contact.comments">Comments:</span>
          <div v-for="comment in this.contact.comments" :key="comment.id">
            {{ comment.comment }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-body">
      <div class="text-center my-2">
        <span
          class="spinner-border spinner-border-sm mx-auto"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminReservationContact",
  props: {
    header: String,
    contact: Object
  },
  computed: {
    name() {
      return this.contact
        ? this.contact.firstName + " " + this.contact.lastName
        : "";
    }
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
}
</style>
