<template>
  <div>
    <div
      v-for="alert in alerts"
      :key="alert.id"
      class="alert alert-primary alert-dismissable rounded-0 my-0"
      role="alert"
    >
      <div class="alert-message">
        <div class="float-right">
          <button
            type="button"
            class="close ml-auto text-white"
            data-dismiss="alert"
            @click="dismissAlert(alert)"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div v-html="alert.message"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertService from "@/services/AlertService.js";

export default {
  name: "TenantAlert",
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    authSource() {
      return this.$store.getters["auth/authSource"];
    }
  },
  data() {
    return {
      alerts: [],
      dismissedAlerts: []
    };
  },
  methods: {
    async getGlobalAlerts() {
      const alertService = new AlertService(this.tenantId);
      this.alerts = await alertService.getGlobalWebAlerts();
      if (this.authSource === "admin") {
        this.filterAlerts();
      }
    },
    dismissAlert(alert) {
      this.dismissedAlerts.push(alert.id);
      sessionStorage.setItem(
        "dismissedAlerts",
        JSON.stringify(this.dismissedAlerts)
      );
    },
    filterAlerts() {
      this.dismissedAlerts = JSON.parse(
        sessionStorage.getItem("dismissedAlerts")
      );
      if (this.dismissedAlerts != null) {
        this.alerts = this.alerts.filter(
          x => !this.dismissedAlerts.includes(x.id)
        );
      } else {
        this.dismissedAlerts = [];
      }
    }
  },
  created() {
    this.getGlobalAlerts();
  }
};
</script>
