<template>
  <div class="my-3 mx-4">
    <TenantAlert />
    <LocationAlert
      :locationId="+reservation.locationId"
      v-if="reservation.locationId"
    />
    <div class="row">
      <div class="col-12">
        <div class="heading">
          {{
            this.showDetailsForm
              ? "Assign Details to Reservation"
              : "Assign Primary Occupant to Reservation"
          }}
        </div>
        <br />
        <AdminReservationContact
          header="Reservation Customer"
          :contact="customer"
        />
        <AdminReservationInfo />
        <FormErrorAlert
          v-if="errors.length > 0"
          :errors="errors"
          :formRef="this.$refs.addDetailsForm"
        />
        <ValidationObserver ref="addDetailsForm">
          <div class="card" v-show="this.showDetailsForm">
            <div class="card-header">
              <h4 class="card-title mt-2 mb-0">Details</h4>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col col-md-6 col-sm-12">
                  <div class="form-group">
                    <TextInput
                      :rules="
                        `required|numeric|${
                          checkPermission('ReservationManagementOverrideOnAdd')
                            ? 'between:1,10000'
                            : 'between:' + basePeople + ',' + maxPeople
                        }`
                      "
                      name="Number of People"
                      id="numberOfAdults"
                      placeholder="Enter number of people"
                      v-model="reservationDetails.numberOfAdults"
                      @change="
                        fieldChange({
                          maxAmountProp: 'maxPeople',
                          propName: 'numberOfAdults',
                          fieldName: 'Number of People'
                        })
                      "
                    />
                    <p class="pl-1" v-if="basePeople || maxPeople">
                      ({{ basePeople ? "Min:" + basePeople + " " : ""
                      }}{{ maxPeople ? "Max: " + maxPeople : "" }})
                    </p>
                  </div>
                  <div class="form-group">
                    <TextInput
                      :rules="
                        `required|numeric|${
                          checkPermission('ReservationManagementOverrideOnAdd')
                            ? 'between:0,500'
                            : 'between:0,' + maxVehicles
                        }`
                      "
                      name="Number of Vehicles"
                      id="numberOfVehicles"
                      placeholder="Enter number of vehicles"
                      v-model="reservationDetails.numberOfVehicles"
                      @change="
                        fieldChange({
                          maxAmountProp: 'maxVehicles',
                          propName: 'numberOfVehicles',
                          fieldName: 'Number of Vehicles'
                        })
                      "
                    />
                    <p class="pl-1" v-if="maxVehicles">
                      ({{ maxVehicles ? "Max. Total: " + maxVehicles : "" }})
                    </p>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      rules="required"
                      name="Equipment Type"
                      v-slot="{ errors, ariaInput, ariaMsg }"
                    >
                      <label
                        @click="$refs.select.focus()"
                        :class="{ error: errors[0] }"
                        for="equipmentType"
                      >
                        Equipment Type
                        <span class="error">*</span>
                      </label>
                      <select
                        class="form-control form-control-lg"
                        id="equipmentType"
                        v-model="reservationDetails.equipmentType"
                        v-bind="ariaInput"
                      >
                        <option value>Please Choose...</option>
                        <option
                          v-for="spotEquipmentType in reservation.spot
                            .spotEquipmentTypes"
                          :key="spotEquipmentType.equipmentType.id"
                          :value="spotEquipmentType.equipmentType.name"
                          >{{ spotEquipmentType.equipmentType.name }}</option
                        >
                      </select>
                      <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                        <li
                          v-for="(error, index) in errors"
                          :key="index"
                          class="error"
                          v-bind="ariaMsg"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <TextInput
                      :rules="
                        `${
                          reservationDetails.equipmentType !== 'None'
                            ? 'required'
                            : ''
                        }|numeric|between:0,${maxVehicleLength}`
                      "
                      name="Equipment Length (ft)"
                      id="equipmentLength"
                      placeholder="Enter length of equipment"
                      v-model="reservationDetails.equipmentLength"
                    />
                  </div>
                  <p class="mt-2">
                    <span style="color: red;">*</span> Indicates a required
                    field
                  </p>
                </div>
                <div class="col col-md-6 col-sm-12 pl-3 pt-4">
                  <div
                    v-for="discount in discountClasses"
                    :key="discount.id"
                    class="form-group"
                  >
                    <label>
                      <input
                        type="checkbox"
                        :value="discount.id"
                        :id="`discount_class_${discount.id}`"
                        v-model="reservationDetails.discounts"
                      />
                      {{ discount.name }}
                    </label>
                    <p v-html="discount.description"></p>
                  </div>
                  <p class="text-muted m-0">
                    Special rate/discount may be eligible for pass holders,
                    special group and membership holders. Proof of
                    age/membership may be required at the time of check in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <button
          role="button"
          class="btn btn-lg btn-primary mr-2 mb-2 float-left"
          @click="goForward"
        >
          {{ this.showDetailsForm ? "Continue Reservation" : "Continue" }}
        </button>
        <button
          type="button"
          @click="goBack"
          class="btn btn-lg btn-secondary mr-2 mb-2 float-left"
        >
          Change Reservation Customer
        </button>
        <button
          type="button"
          @click="goHome"
          class="btn btn-cancel btn-lg mr-2 mb-2 float-left"
        >
          Cancel
        </button>
      </div>
    </div>
    <b-modal
      id="confirmDiscountModal"
      title="Residency Required"
      no-stacking
      no-close-on-backdrop
    >
      <p class="my-4">
        Special Discounted rates are applicable for Illinois residents only.
        Based on your customer, customer is not eligible for this discount. If
        you think customer should be eligible, then update customer profile for
        the IL address under customer profile. Customer Identification card with
        Address is required at check-in.
      </p>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn" variant="primary" @click="discardAndGoToProfile"
          >Discard Reservation and Go to Profile</b-button
        >
        <b-button
          class="btn"
          variant="secondary"
          @click="continueReservation(ok)"
          >Continue With Reservation</b-button
        >
      </template>
    </b-modal>
    <AdminOverrideAlertModal
      header="Do you wish to override this field?"
      modalRef="override-field-modal"
      :overriddenProp="overriddenField"
      @cancel="overrideCancel"
    />
  </div>
</template>

<script>
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import TenantService from "@/services/TenantService.js";
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationInfo from "@/components/admin/AdminReservationInfo.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import TenantAlert from "@/components/alert/TenantAlert.vue";
import LocationAlert from "@/components/alert/LocationAlert.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminOverrideAlertModal from "@/components/admin/AdminOverrideAlertModal.vue";

export default {
  name: "AdminReservationAddDetails",
  title: "Admin - Reservation Add Details",
  mixins: [checkPermissionMixin],
  components: {
    AdminReservationContact,
    AdminReservationInfo,
    ValidationProvider,
    ValidationObserver,
    FormErrorAlert,
    TextInput,
    TenantAlert,
    LocationAlert,
    AdminOverrideAlertModal
  },
  data() {
    return {
      customer: null,
      errors: [],
      reservationSansOccupant: {},
      showDetailsForm: false,
      peopleNumbers: [],
      vehicleNumbers: [],
      equipmentTypes: [],
      reservationDetails: {
        numberOfAdults: "",
        numberOfVehicles: "",
        discounts: [],
        equipmentType: "",
        equipmentLength: ""
      },
      discountClasses: [],
      discountAlertShown: false,
      overriddenField: null
    };
  },
  methods: {
    goHome() {
      this.$store.dispatch("transaction/clear");
      this.$router.push("/admin").catch(() => {});
    },
    goBack() {
      this.$store.commit("transaction/setReservation", {
        ...this.reservationSansOccupant,
        overrideBusinessRule: false
      });
      this.$router.push("/admin/reservation-add-occupant").catch(() => {});
    },
    goForward() {
      if (!this.showDetailsForm) {
        this.showDetailsForm = true;
      } else {
        this.$refs.addDetailsForm.validate().then(async success => {
          if (!success) {
            setTimeout(() => {
              const errors = Object.entries(this.$refs.addDetailsForm.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error["value"].length);
              this.errors = errors;
              this.$refs.addDetailsForm.refs[
                errors[0]["key"]
              ].$el.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }, 100);
          } else {
            this.errors = [];
            this.verifyCustomerQualitiesForDiscounts().then(verified => {
              if (!verified && !this.discountAlertShown) {
                //Show modal
                this.$bvModal.show("confirmDiscountModal");
                this.discountAlertShown = true;
                return;
              }
              this.$store.commit("transaction/setReservation", {
                ...this.reservation,
                ...this.reservationDetails
              });
              this.$router.push("/admin/reservation-review").catch(() => {});
            });
          }
        });
      }
    },
    discardAndGoToProfile() {
      //Discard reservation
      const spotReservationService = new AdminSpotReservationService(
        this.tenantId
      );
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      spotReservationService
        .releaseSpotClaim(claimData.spotId, claimData.lockCode)
        .then(response => {
          if (response.statusCode === "Success") {
            this.$store.dispatch("transaction/clearReservation");
          }
        });

      //Redirect to customer profile
      const customerId = this.reservation.customerId;
      this.$store.dispatch("transaction/clear");
      this.$router.push("/admin/customer-edit/" + customerId).catch(() => {});
    },
    continueReservation(ok) {
      ok();
      this.reservationDetails.discounts = [];
      this.goForward();
    },
    async verifyCustomerQualitiesForDiscounts() {
      //Pass discounts to backend to verify which have residency requirement and if they've been met
      let customerDiscounts = {
        discounts: [...this.reservationDetails.discounts]
      };
      if (customerDiscounts.discounts.length == 0) return true;

      let profileService = new AdminProfileService(this.tenantId);
      let response = await profileService.doesCustomerQualifyForDiscounts(
        this.reservation.customerId,
        customerDiscounts
      );
      if (response.statusCode == "Success" && response.data === true) {
        return response.data;
      }
      return false;
    },
    async getCustomer() {
      this.reservationSansOccupant = this.reservation;
      const profileService = new AdminProfileService(this.tenantId);
      const customerResponse = await profileService.getCustomerByIdAdmin(
        this.reservation.customerId
      );
      const customer = customerResponse.data;
      const primaryPhone = customer.customerPhoneNumbers.find(x => x.isPrimary);
      this.customer = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        street1: customer.addresses[0].street1,
        street2: customer.addresses[0].street2,
        city: customer.addresses[0].city,
        state: customer.addresses[0].state,
        zipcode: customer.addresses[0].zipcode,
        mobilePhone: primaryPhone
          ? primaryPhone.number
          : customer.customerPhoneNumbers[0].number,
        email: customer.email,
        comments: customer.comments,
        username: customer.user?.username
      };
      if (customer.discountClassId != null) {
        this.reservationDetails.discounts.push(customer.discountClassId);
      }
      this.$store.commit("transaction/setReservation", {
        ...this.reservation,
        ...this.customer,
        externalUserId: customer.user ? customer.user.externalUserId : null
      });
    },
    restoreReservationDetails() {
      //Set reservationDetail values if they already exist.  This is for when the customer comes "back" from review page.
      if (this.reservation.numberOfAdults) {
        this.reservationDetails.numberOfAdults = this.reservation.numberOfAdults;
        this.reservationDetails.numberOfVehicles = this.reservation.numberOfVehicles;
        this.reservationDetails.discounts = this.reservation.discounts;
        this.reservationDetails.equipmentType = this.reservation.equipmentType;
        this.reservationDetails.equipmentLength = this.reservation.equipmentLength;
      }
    },
    async getDiscounts() {
      const tenantService = new TenantService(this.tenantId);
      const response = await tenantService.getDiscountClasses();
      this.discountClasses = response.data;
    },
    overrideCancel(overriddenField) {
      this.reservationDetails[overriddenField.propName] = this.reservation[
        overriddenField.propName
      ];
    },
    fieldChange(changeObject) {
      if (
        +this.reservationDetails[changeObject.propName] <=
        +this[changeObject.maxAmountProp]
      )
        return;

      this.overriddenField = {
        name: changeObject.fieldName,
        value: this.reservationDetails[changeObject.propName],
        ...changeObject
      };
      this.$bvModal.show("override-field-modal");
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservation() {
      return this.$store.getters["transaction/reservation"];
    },
    basePeople() {
      const reservation = this.$store.getters["transaction/reservation"];
      const attr = reservation.spot.attributes.find(
        x => x.globalAttributeId === 203
      );
      return attr ? attr.value : null;
    },
    maxPeople() {
      const reservation = this.$store.getters["transaction/reservation"];
      const attr = reservation.spot?.attributes.find(
        x => x.globalAttributeId === 133
      );
      return attr ? attr.value : null;
    },
    maxVehicles() {
      const reservation = this.$store.getters["transaction/reservation"];
      const attr = reservation.spot?.attributes.find(
        x => x.globalAttributeId === 134
      );
      return attr ? attr.value : null;
    },
    maxVehicleLength() {
      const reservation = this.$store.getters["transaction/reservation"];
      const attr = reservation.spot?.attributes.find(
        x => x.globalAttributeId === 135
      );
      return attr ? attr.value : 0;
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "AdminReservationAddReview") {
      next(vm => {
        vm.showDetailsForm = true;
      });
    } else {
      next();
    }
  },
  created() {
    this.getCustomer();
    this.getDiscounts();
    this.restoreReservationDetails();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
.summary {
  min-height: 510px;
}
</style>
