import AdminSearchApi from "@/generatedapiclients/src/api/AdminSearchApi.js";
import AdminCustomerApi from "@/generatedapiclients/src/api/AdminCustomerApi.js";
import moment from "moment-timezone";

export default class AdminProfileService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminSearchApi = new AdminSearchApi();
    this._adminCustomerApi = new AdminCustomerApi();
  }

  async search(searchCriteria, paging) {
    return this._adminSearchApi.v1TenantTenantIdAdminAdminSearchCustomersGet(
      this._tenantId,
      {
        ...searchCriteria,
        ...paging
      }
    );
  }

  async sendReservationConfirmationEmail(resendConfirmationEmailRequest) {
    return this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerSendconfirmationemailPost(
      this._tenantId,
      {
        resendConfirmationEmailRequest: resendConfirmationEmailRequest
      }
    );
  }

  async createCustomerProfileAdmin(newCustomerRequest, createUser = true) {
    newCustomerRequest.timezoneId = moment.tz.guess();
    const response = await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerPost(
      this._tenantId,
      { newCustomerRequest, createUser }
    );
    return response;
  }

  async getCustomerHistory(customerId) {
    return await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerCustomerIdHistoryGet(
      customerId,
      this._tenantId
    );
  }

  async editCustomerProfileAdmin(editCustomerRequest) {
    const response = await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerPut(
      this._tenantId,
      { editCustomerRequest }
    );
    return response;
  }

  async getCustomerStatuses() {
    const response = await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerCustomerstatusesAllGetWithHttpInfo(
      this._tenantId
    );
    return response.response.body;
  }

  async getCustomerByIdAdmin(customerId) {
    const response = await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerCustomerIdGet(
      customerId,
      this._tenantId
    );
    return response;
  }

  async checkUsernameAvailability(username) {
    return await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerUsernameAvailabilityGet(
      this._tenantId,
      {
        username
      }
    );
  }

  async confirmUserRegistration(confirmUserRegistrationRequest) {
    const response = await this._userApi.v1TenantTenantIdUserConfirmUserRegistrationPost(
      this._tenantId,
      { confirmUserRegistrationRequest }
    );
    return response;
  }

  async resendRegistrationCode(userId) {
    await this._userApi.v1TenantTenantIdUserResendRegistrationCodeUserIdPost(
      userId,
      this._tenantId
    );
  }

  async doesCustomerQualifyForDiscounts(customerId, customerDiscounts) {
    return await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerCustomerIdVerifydiscountsPost(
      customerId,
      this._tenantId,
      {
        customerDiscounts: { ...customerDiscounts }
      }
    );
  }

  async addCustomerComment(customerId, comment) {
    return await this._adminCustomerApi.v1TenantTenantIdAdminAdminCustomerCustomerIdAddCommentPost(
      customerId,
      this._tenantId,
      {
        comment
      }
    );
  }
}
